<template>
    <div class="header_space">
        <div v-if="loadingStatus === 'pending'" class="loading_image">
            <h1 class="main_font">
                {{ loadingText }}
            </h1>
            <img src="../assets/loading.gif" />
        </div>

        <tree
            ref="semanticsTree"
            :visibility="loadingStatus === 'done' ? 'visible' : 'hidden'"
            class="main_font"
        />
    </div>
</template>

<script>
import createLogger from '../util/createLogger';
import readCookie from '../util/readCookie';
import invokeS3ToEfs from '../util/api/invokeS3ToEfs';
import invokeIfcCompliance from '../util/api/invokeIfcCompliance';

import treeifyJson from './render/treeifyJson';

const log = createLogger('Compliance');

function setHeader() {
    this.$root.$emit('set_header_title', this.header);
}

function initTreeComponent(complianceData) {
    log.info('initTreeComponent');

    const treeifiedJson = treeifyJson(complianceData);

    const { tree } = this.$refs.semanticsTree;

    treeifiedJson.text = 'Compliance';
    treeifiedJson.state = {
        expanded: true,
    };

    tree.setModel(treeifiedJson);
}

export default {
    props: {
        objectKey: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            loadingStatus: null,
            loadingText: '',
            header: {
                title: 'Compliance',
            },
        };
    },

    async mounted() {
        log.info('compliance');
        this.setHeader();

        const idToken = readCookie('id_token');

        if (!idToken) {
            log.info('not signed in');
            return;
        }

        this.loadingStatus = 'pending';

        this.loadingText = 'preparing your files...';
        const copyToEfsResponseData = await invokeS3ToEfs({
            objectKey: this.objectKey,
            authorizationValue: `Bearer ${idToken}`,
        });

        this.loadingText = 'calculating compliance...';
        const calculateComplianceResponseData = await invokeIfcCompliance(
            copyToEfsResponseData.data,
        );

        this.loadingStatus = 'done';

        this.initTreeComponent(calculateComplianceResponseData);
    },

    methods: {
        initTreeComponent,
        setHeader,
    },
};
</script>

<!--
    repeated code in Compliance.vue and Render.vue
    for some reason this only has effect when it is not 'scoped'
-->
<style>
.tree-arrow.has-child::after {
    border-color: grey !important;
    border-width: 2px !important;
}

.tree-node.selected > .tree-content {
    background-color: var(--main_color_fg_dark) !important;
}

.tree-node.selected > .tree-content > .tree-anchor {
    color: white;
}
</style>
